import { useEffect } from 'react'
import { Card, Grid, Button } from '@nextui-org/react'
import { useSession, signIn } from 'next-auth/react'
import { useRouter } from 'next/router'
import { observer } from 'mobx-react-lite'

import type { FunctionComponent } from 'react'

import { getNoLayout } from '@components/layouts'

const Index = observer(function () {
	const router = useRouter()
	const { data: session } = useSession()

	useEffect(() => {
		if (!session) {
			return
		}

		router.push('/dashboard')
	}, [session, router])

	const handleSignup = async () => {
		router.push('/signup')
	}

	const handleLogin = async () => {
		signIn()
	}

	return (
		<Grid.Container justify="center" gap={2} css={{ marginTop: '100px' }}>
			<Grid xs={12} sm={8} lg={4}>
				<Card>
					<Grid.Container gap={2}>
						<Grid>
							<Button auto shadow size="xl" onClick={handleSignup}>
								Sign Up
							</Button>
						</Grid>
						<Grid>
							<Button auto shadow size="xl" onClick={handleLogin}>
								Login
							</Button>
						</Grid>
					</Grid.Container>
				</Card>
			</Grid>
		</Grid.Container>
	)
}) as FunctionComponent & { getLayout: typeof getNoLayout }

Index.getLayout = getNoLayout

export default Index
